import React from "react";
import Navbar from "../components/Navbar";
import Comp from "../components/Comp";
import GoldArrow from "../assets/images/gold-arrow.png";
import bbbheader_img from "../assets/images/dark-header-bg.png";
import Africa_icon from "../assets/images/africa-image.png";
import creditcard_icon from "../assets/images/credit-card-icon.png";
import brain_icon from "../assets/images/brain-icon.png";
import boat_img from "../assets/images/boat-image.png";
import afrodigital_bg_img1 from "../assets/images/Screenshot 2023-11-17 150125 2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import BackToTopButton from "../components/Backtotop";

import { Link } from "react-router-dom";

export default function BrandsbyBiyi() {
  const location = useLocation();
  const product = location.state.product;
  console.log(product);

  useEffect(() => {
    Aos.init();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="product-det">
      <Navbar />
      <div className="bbb-header">
        <div className="bbb-header-content">
          <h1 data-aos="fade-up">{product.headlines.headlineTitle}</h1>
          <p data-aos="fade-up">{product.headlines.headlineSubtitle}</p>
          <Link to={product.headlines.headlineButtonLink} data-aos="fade-up">
            <span>{product.headlines.headlineButtonTitle}</span>
            <img src={GoldArrow} alt="" />
          </Link>
        </div>
      </div>
      <div className="why-bbb">
        <h1 className="wb-subheading" data-aos="fade-up">
          Why BBB?
        </h1>
        <div className="wb-content">
          {product?.features.map((feature, index) => (
            <section key={index} data-aos="fade-up">
              <figure className="wbc-fig">
                <img src={feature?.featuresImage} alt="" />
              </figure>
              <h4>{feature?.featuresHeadlineText}</h4>
              <p>
               {feature?.featuresContent}
              </p>
            </section>
          ))}
        </div>
        <div className="stand-out-section">
          <figure data-aos="fade-up">
            <img src={product?.moreFeatures?.moreFeaturesImage} alt={product?.moreFeatures?.moreFeaturesAltText} />
          </figure>
          <div className="sos-content" data-aos="fade-up">
            <h1>{product?.moreFeatures?.moreFeaturesHeadlineText}</h1>
            <Link to={product?.moreFeatures?.moreFeaturesButtonLink}>
              <span>Learn more</span>
              <img src={GoldArrow} alt="" />
            </Link>
          </div>
        </div>
      </div>
      <div className="what-client-say">
        <h1 data-aos="fade-up">What clients say</h1>
        <Slider {...settings} className="wsc-slider">
          {product?.testimony.map((testimony, index) => (
            <div key={index} className="wsc-item" data-aos="fade-up">
              <p>{testimony?.testimonyContent}</p>
              <h5>-{testimony?.testimonyHeadlineText}</h5>
            </div>
          ))}
          {/* <div className="wsc-item" data-aos="fade-up">
            <p>
              This book changed my life i have never read anything like it . The
              way it works and how it goes.
            </p>
            <h5>-Dieazni</h5>
          </div> */}
          {/* <div className="wsc-item" data-aos="fade-up">
            <p>
              This book changed my life i have never read anything like it . The
              way it works and how it goes.
            </p>
            <h5>-Dieazni</h5>
          </div>
          <div className="wsc-item" data-aos="fade-up">
            <p>
              This book changed my life i have never read anything like it . The
              way it works and how it goes.
            </p>
            <h5>-Dieazni</h5>
          </div>
          <div className="wsc-item" data-aos="fade-up">
            <p>
              This book changed my life i have never read anything like it . The
              way it works and how it goes.
            </p>
            <h5>-Dieazni</h5>
          </div>
          <div className="wsc-item" data-aos="fade-up">
            <p>
              This book changed my life i have never read anything like it . The
              way it works and how it goes.
            </p>
            <h5>-Dieazni</h5>
          </div> */}
        </Slider>

        <div className="wsc-consult-section">
          {/* <figure data-aos="fade-up"></figure> */}
          <Link
            to={product.punch.buttonink}
            data-aos="fade-up"
            className="consult-btn"
          >
            {product?.punch?.buttonTitle}
          </Link>
        </div>
      </div>
      <BackToTopButton />
      <Comp />
    </div>
  );
}
