import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Comp from '../components/Comp';
import afrodigital_bg_img1 from "../assets/images/Screenshot 2023-11-17 150125 2.png";
import tree_bg from "../assets/images/tree-background-image.png";
import GoldArrow from "../assets/images/gold-arrow.png"
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import BackToTopButton from '../components/Backtotop';

export default function Lightpage() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const location = useLocation();
    const lightPage = location.state.lightPage;
    const [inputValue, setInputValue] = useState("");
    console.log(lightPage);

    useEffect(() => {
        Aos.init();
      }, []);

  return (
    <div>
        <Navbar/>
        <div className="light-page-view">
            <section className="lpv-side1" data-aos="fade-up">
                <figure>
                    <img src={lightPage?.innerPage?.innerImage} alt={lightPage?.innerPage?.alt} />
                </figure>
                <h1>{lightPage?.title} </h1>
                <p className='light-pages-texthov-mobile'>
                    {lightPage?.innerPage?.content}
                </p>
            </section>
            <form action="" >
                <p className='light-page-text'>
                {lightPage?.innerPage?.content}
                </p>
                <input type="email" placeholder='Enter email address to download' value={inputValue} onChange={(e) => setInputValue(e.target.value)} data-aos="fade-up"/>
                <button type="submit" className='lp-download-btn' data-aos="fade-up">
                    Download
                </button>
            </form>
        </div>
        <BackToTopButton/>
        <Comp/>
    </div>
  )
}
